export type actionType = {
  type: string;
  error?: boolean;
  payload?: any;
};

export interface IBaseStateProps {
  loading: boolean;
  error: boolean;
  data?: any;
}

export const ACTIONS = {
  PRODUCTS: {
    LIST: {
      FETCH: 'PRODUCTS_LIST_FETCH',
      SUCCESS: 'PRODUCTS_LIST_SUCCESS',
      FAIL: 'PRODUCTS_LIST_FAIL'
    },
    CHOICE: 'PRODUCTS_CHOICE'
  },
  CHECKOUT: {
    RESET: 'CHECKOUT_RESET',
    FIND_DOC_NUMBER: {
      FETCH: 'CHECKOUT_FIND_DOC_NUMBER_FETCH',
      SUCCESS: 'CHECKOUT_FIND_DOC_NUMBER_SUCCESS',
      FAIL: 'CHECKOUT_FIND_DOC_NUMBER_FAIL',
      RESET: 'CHECKOUT_FIND_DOC_NUMBER_RESET'
    },
    UPDATE_CLIENT_INFO: 'CHECKOUT_UPDATE_CLIENT_INFO',
    UPDATE_ADDRESS_INFO: 'CHECKOUT_UPDATE_ADDRESS_INFO',
    UPDATE_PAYMENT_INFO: 'CHECKOUT_UPDATE_PAYMENT_INFO',
    ZIPCODE: {
      FETCH: 'CHECKOUT_ZIPCODE_FETCH',
      SUCCESS: 'CHECKOUT_ZIPCODE_SUCCESS',
      FAIL: 'CHECKOUT_ZIPCODE_FAIL'
    },
    CONFIRMATION: {
      FETCH: 'CHECKOUT_CONFIRMATION_FETCH',
      SUCCESS: 'CHECKOUT_CONFIRMATION_SUCCESS',
      FAIL: 'CHECKOUT_CONFIRMATION_FAIL'
    },
    PAYMENT_URL: {
      FETCH: 'CHECKOUT_PAYMENT_URL_FETCH',
      SUCCESS: 'CHECKOUT_PAYMENT_URL_SUCCESS',
      FAIL: 'CHECKOUT_PAYMENT_URL_FAIL'
    }
  }
};
