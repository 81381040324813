import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Redirect, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CancelIllustration from '../../assets/img/CancelIllustration.jpg';
import CreatingOrderIllustration from '../../assets/img/CreatingOrderIllustration.svg';

import { Analytics } from '@wdynamo/common/lib/services';
import {
  DocumentInfo,
  ClientInfo,
  AddressInfo,
  InfoConfirmation,
  SuccessModal,
  UnclosableModal,
  Stepper
} from '@wdynamo/common/lib/components';
import { substractTime } from '@wdynamo/common/lib/utils';
// eslint-disable-next-line no-unused-vars
import { IButtonsMessages } from '@wdynamo/common/lib/models';

import SuccessIllustration from '../../assets/img/SuccessIllustration.jpg';
import ErrorIllustration from '../../assets/img/ErrorIllustration.svg';

// eslint-disable-next-line no-unused-vars
import { ICheckoutProps, PaymentMethods } from './models';

import { uris } from '../../siteMap';

interface CheckoutComponentProps {
  checkoutStore: ICheckoutProps;
  getDocNumberInfoFetch(docType: string, docNumber: string): void;
  updateClientInfo(fieldKey: string, value: string): void;
  updateAddressInfo(fieldKey: string, value: string): void;
  updatePaymentInfo(fieldKey: string, value: string): void;
  confirmationFetch(checkout: object): void;
  getPaymentUrlFetch(orderId: string | number): void;
  resetCheckout(): void;
  currentStep?: number;
  className?: string;
  [key: string]: any;
}

const usePrevious = (value: ICheckoutProps): ICheckoutProps => {
  const ref = useRef<ICheckoutProps>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as ICheckoutProps;
};

const CleanCheckoutComponent: React.FC<CheckoutComponentProps> = (props: CheckoutComponentProps) => {
  const { t } = useTranslation();
  const { checkoutStore, currentStep } = props;
  const previousCheckStore = usePrevious(checkoutStore);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [getPaymentUrlTries, setGetPaymentUrlTries] = React.useState<number>(currentStep || 0);
  const [saleWasSuccess, setSaleWasSuccess] = React.useState<boolean>(true);
  const [disabledFinishSale, setDisabledFinishSale] = React.useState<boolean>(false);
  const steps = [
    t('CHECKOUT.STEP_1.STEPPER'),
    t('CHECKOUT.STEP_2.STEPPER'),
    t('CHECKOUT.STEP_3.STEPPER'),
    t('CHECKOUT.STEP_4.STEPPER')
  ];
  const stepperMessages: { [BUTTONS: string]: IButtonsMessages } = {
    BUTTONS: {
      CANCEL: activeStep === 0 ? t('CHECKOUT.BUTTONS.CANCEL') : t('CHECKOUT.BUTTONS.GOBACK'),
      CONTINUE: activeStep === steps.length - 1 ? t('CHECKOUT.BUTTONS.SELL') : t('CHECKOUT.BUTTONS.CONTINUE')
    }
  };
  const defaultRedirect = <Redirect to={uris.products.uri} />;

  useEffect(() => {
    Analytics().pageview('display-checkout');
  }, []);

  useEffect(() => {
    if (previousCheckStore?.payment.loading && !checkoutStore.payment.loading) {
      if (!checkoutStore.payment.error) {
        if (getPaymentUrlTries < 15 && !checkoutStore.payment.paymentUrl) {
          setGetPaymentUrlTries((prevGetPaymentUrlTries) => prevGetPaymentUrlTries + 1);
          setTimeout(() => props.getPaymentUrlFetch(checkoutStore.confirmation.orderId!), 2000);
        } else if (checkoutStore.payment.paymentUrl) {
          // @Thats a local work arround
          window.location.replace(
            `${checkoutStore.payment.paymentUrl}&callbackURL=${window.location.origin.replace('http://', 'https://')}${
              uris.products.uri
            }`
          );
        } else displayErrorMessage();
      } else displayErrorMessage();
    }
  }, [checkoutStore.payment.loading]);

  useEffect(() => {
    if (previousCheckStore?.docInfo.loading && !checkoutStore.docInfo.loading) handleNext();
  }, [checkoutStore.docInfo]);

  useEffect(() => {
    if (previousCheckStore?.confirmation.loading && !checkoutStore.confirmation.loading) {
      if (checkoutStore.confirmation.paymentMethod === PaymentMethods.EXPRESS)
        setTimeout(() => props.getPaymentUrlFetch(checkoutStore.confirmation.orderId!), 2000);
      else {
        if (checkoutStore.confirmation.error) setSaleWasSuccess(false);
        handleNext();
      }
    }
  }, [checkoutStore.confirmation]);

  const displayErrorMessage = () => {
    setSaleWasSuccess(false);
    handleNext();
  };

  const cancelOrder = () => window.location.replace(uris.products.uri);

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        Analytics().pageview('checkout | search-by-document');
        return (
          <DocumentInfo
            docInfo={checkoutStore.docInfo}
            actualStep={stepIndex}
            handleBack={handleBack}
            handleNext={getDocNumberInfo}
            messages={{
              TITLE: t('CHECKOUT.STEP_1.TITLE'),
              DOCUMENT_TYPE: t('CHECKOUT.STEP_1.DOCUMENT_TYPE'),
              DOCUMENT_NUMBER: t('CHECKOUT.STEP_1.DOCUMENT_NUMBER'),
              BUTTONS: {
                ...stepperMessages.BUTTONS
              }
            }}
          />
        );
      case 1: {
        Analytics().pageview('checkout | client-info');
        const minAge: moment.Moment = moment(substractTime(moment().format(), 'years', 18));
        const minDatepickerDate: moment.Moment = moment(substractTime(moment().format(), 'years', 150));
        return (
          <ClientInfo
            clientInfo={checkoutStore.clientInfo}
            actualStep={stepIndex}
            handleBack={handleBack}
            handleNext={handleNext}
            onInputChange={props.updateClientInfo}
            minAge={minAge}
            minDate={minDatepickerDate}
            messages={{
              MALE: t('COMMONS.GENDER.MALE'),
              FEMALE: t('COMMONS.GENDER.FEMALE'),
              TITLE: t('CHECKOUT.STEP_2.TITLE'),
              FIRST_NAME: t('CHECKOUT.STEP_2.FIRST_NAME'),
              LAST_NAME: t('CHECKOUT.STEP_2.LAST_NAME'),
              BORN_DATE: t('CHECKOUT.STEP_2.BORN_DATE'),
              EMAIL: t('CHECKOUT.STEP_2.EMAIL'),
              EMAIL_SUGGESTION: t('CHECKOUT.STEP_2.EMAIL_SUGGESTION'),
              EMAIL_CHECKER: t('CHECKOUT.STEP_2.EMAIL_CHECKER'),
              GENDER: t('CHECKOUT.STEP_2.GENDER'),
              PHONE: t('CHECKOUT.STEP_2.PHONE'),
              PHONE_HELP: t('CHECKOUT.STEP_2.PHONE_HELP'),
              MAX_DATE_MESSAGE: t('CHECKOUT.STEP_2.MAX_DATE', {
                maxDate: minAge.format(process.env.REACT_APP_DATE_FORMAT!)
              }),
              MIN_DATE_MESSAGE: t('CHECKOUT.STEP_2.MIN_DATE', {
                minDate: minDatepickerDate.format(process.env.REACT_APP_DATE_FORMAT!)
              }),
              INVALID_DATE_MESSAGE: t('CHECKOUT.STEP_2.INVALID_DATE'),
              INVALID_DATE_LABEL_MESSAGE: t('CHECKOUT.STEP_2.INVALID_DATE_LABEL'),
              BUTTONS: {
                ...stepperMessages.BUTTONS
              }
            }}
          />
        );
      }
      case 2:
        Analytics().pageview('checkout | address-info');
        return (
          <AddressInfo
            clientAddress={checkoutStore.clientAdress}
            actualStep={stepIndex}
            handleBack={handleBack}
            handleNext={handleNext}
            onInputChange={props.updateAddressInfo}
            messages={{
              TITLE: t('CHECKOUT.STEP_3.TITLE'),
              STREET: t('CHECKOUT.STEP_3.STREET'),
              NUMBER: t('CHECKOUT.STEP_3.NUMBER'),
              FLOOR: t('CHECKOUT.STEP_3.FLOOR'),
              DOOR: t('CHECKOUT.STEP_3.DOOR'),
              STATE: t('CHECKOUT.STEP_3.STATE'),
              CITY: t('CHECKOUT.STEP_3.CITY'),
              ZIPCODE: t('CHECKOUT.STEP_3.ZIPCODE'),
              BUTTONS: {
                ...stepperMessages.BUTTONS
              }
            }}
          />
        );
      case 3:
        Analytics().pageview('checkout | confirmation');
        return (
          <InfoConfirmation
            checkoutStore={checkoutStore}
            actualStep={stepIndex}
            handleBack={handleBack}
            onChange={props.updatePaymentInfo}
            onConfirm={confirmCheckout}
            cancelModalCallback={cancelOrder}
            messages={{
              PAYMENT_SUBSCRIPTION: t('CHECKOUT.STEP_4.PAYMENT_SUBSCRIPTION'),
              YES: t('COMMONS.YES'),
              NO: t('COMMONS.NO'),
              TITLE: t('CHECKOUT.STEP_4.TITLE'),
              CLIENT_TITLE: t('CHECKOUT.STEP_4.CLIENT.TITLE'),
              CLIENT_FIRST_NAME: t('CHECKOUT.STEP_4.CLIENT.FIRST_NAME'),
              CLIENT_LAST_NAME: t('CHECKOUT.STEP_4.CLIENT.LAST_NAME'),
              CLIENT_DOC_TYPE: t('CHECKOUT.STEP_4.CLIENT.DOC_TYPE'),
              CLIENT_EMAIL: t('CHECKOUT.STEP_4.CLIENT.EMAIL'),
              CANCEL: t('CHECKOUT.BUTTONS.CANCEL'),
              BUTTONS: {
                ...stepperMessages.BUTTONS
              }
            }}
            cancelModalMessages={{
              TITLE: t('COMMONS.MODALS.CANCEL.TITLE'),
              CANCEL_BUTTON: t('COMMONS.MODALS.CANCEL.CANCEL'),
              CONTINUE_BUTTON: t('COMMONS.MODALS.CANCEL.CONTINUE')
            }}
            cancelModalImg={CancelIllustration}
            disabledNextButton={disabledFinishSale}
          />
        );
      case 4:
        return (
          <SuccessModal
            open={true}
            handleClose={() => window.location.replace(uris.products.uri)}
            onClickConfirm={() => window.location.replace(uris.products.uri)}
            img={saleWasSuccess ? SuccessIllustration : ErrorIllustration}
            messages={{
              TITLE: saleWasSuccess ? t('CHECKOUT.FINISHED.SUCCESS.TITLE') : t('CHECKOUT.FINISHED.FAILED.TITLE'),
              CONTINUE_BUTTON: t('COMMONS.BUTTONS.CONTINUE')
            }}
            description={saleWasSuccess ? t('CHECKOUT.FINISHED.SUCCESS.TITLE') : t('CHECKOUT.FINISHED.FAILED.MESSAGE')}
          />
        );
      default:
        return defaultRedirect;
    }
  };

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getDocNumberInfo = (docType: string, docNumber: string) => props.getDocNumberInfoFetch(docType, docNumber);

  const confirmCheckout = () => {
    setDisabledFinishSale(true);
    const { checkoutStore } = props;
    const checkout = {
      payment_subscription: checkoutStore.payment.paymentSubscription == 'withPaymentSubscription' ? true : false,
      payment_type: PaymentMethods.EXPRESS,
      items: [
        {
          custom_data: null,
          product: checkoutStore.product?.code.toString().trim()
        }
      ],
      customer: {
        first_name: checkoutStore.clientInfo.firstName.toString().trim(),
        last_name: checkoutStore.clientInfo.lastName.toString().trim(),
        gender: checkoutStore.clientInfo.gender.toString().trim(),
        birth_date: moment(
          checkoutStore.clientInfo.birthDate.replace(/-/gi, '/'),
          process.env.REACT_APP_DATE_FORMAT!
        ).format('YYYY-MM-DD'),
        nationality: 'ARG',
        contributor_type: 'CONSUMIDOR_FINAL',
        identity: {
          type: checkoutStore.docInfo.selectedDocType.toString().trim(),
          value: checkoutStore.docInfo.docNumber.toString().trim()
        },
        email: checkoutStore.clientInfo.email.toString().trim(),
        phones: [
          {
            number: checkoutStore.clientInfo.phone.toString().trim(),
            type: 'MOBILE',
            code: '54'
          }
        ],
        type: 'NATURAL',
        address: {
          zip_code: checkoutStore.clientAdress.zipCode.toString().trim(),
          street: checkoutStore.clientAdress.street.toString().trim(),
          number: checkoutStore.clientAdress.number?.toString().trim(),
          floor: checkoutStore.clientAdress.floor?.toString().trim(),
          door: checkoutStore.clientAdress.door?.toString().trim(),
          city: checkoutStore.clientAdress.city?.toString().trim(),
          state: checkoutStore.clientAdress.state?.toString().trim()
        }
      }
    };
    props.confirmationFetch(checkout);
  };

  const renderCheckout = () => {
    return (
      <div className={`${props.className} checkout`}>
        <Stepper activeStep={activeStep} steps={steps} className='checkout-stepper' />
        <div>{getStepContent(activeStep)}</div>
      </div>
    );
  };

  return (
    <>
      {disabledFinishSale && (
        <UnclosableModal img={CreatingOrderIllustration} messages={{ TITLE: t('CHECKOUT.STEP_4.PROCESSING') }} />
      )}
      {checkoutStore.product ? (
        renderCheckout()
      ) : (
        <Route exact path={uris.checkout.uri}>
          {defaultRedirect}
        </Route>
      )}
    </>
  );
};

export const CheckoutComponent = styled(({ ...props }) => <CleanCheckoutComponent {...props} />)`
  .action-buttons {
    .cancel-button button {
      color: var(--color-grey-text) !important;
      border: none !important;
      &:hover {
        border: 1px solid var(--color-grey-text) !important;
      }
    }
    .steps-buttons {
      .checkout-form-buttons {
        justify-content: flex-end;
      }
      .cancel {
        display: none;
      }
    }

    @media screen and (max-width: 450px) {
      margin-top: 20px;
      > div {
        display: flex;
        flex-direction: column-reverse;
      }
      button {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
`;
