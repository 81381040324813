import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Analytics } from '@wdynamo/common/lib/services';
import {
  DesktopProductsComponent,
  // eslint-disable-next-line no-unused-vars
  IProductsStyles,
  TextPoint
} from '@wdynamo/common/lib/components';

import { COLORS } from '../../assets/themes/colors';
import { uris } from '../../siteMap';
// eslint-disable-next-line no-unused-vars
import { IBaseStateProps } from '../ActionTypes';

// eslint-disable-next-line no-unused-vars
import { ICheckoutProps } from '../Checkout';

interface ProductsComponentProps {
  [key: string]: any;
  productsStore: IBaseStateProps;
  checkoutStore: ICheckoutProps;
  getProductsFetch(): void;
  choiceProduct(product: object): void;
  resetCheckout(): void;
  className?: string;
}

const CleanProductsComponent: React.FC<ProductsComponentProps> = (props: ProductsComponentProps) => {
  const { t } = useTranslation();
  const { productsStore, checkoutStore } = props;
  const mainColor = document.body.style.getPropertyValue('--color-main') || '#ff9800';
  const styles: IProductsStyles = {
    FEATURED: {
      BORDER: mainColor,
      BACKGROUND: mainColor,
      BORDER_BOTTOM: COLORS.white,
      TEXT_COLOR: COLORS.white
    },
    COMMON: {
      BORDER_BOTTOM: COLORS.greyText,
      TITLE_COLOR: COLORS.black,
      TEXT_COLOR: COLORS.greyText
    }
  };

  useEffect(() => {
    Analytics().pageview('display-products');
    props.resetCheckout();
    props.getProductsFetch();
  }, []);

  const onSell = (product: object) => props.choiceProduct(product);

  return (
    <div className={`${props.className} products`}>
      <h1>
        {t('PRODUCTS.TITLE')}
        <TextPoint />
      </h1>
      {checkoutStore.product ? (
        <Redirect to={uris.checkout.uri} />
      ) : (
        <DesktopProductsComponent
          productsList={productsStore.data}
          onSell={onSell}
          styles={styles}
          defaultProductImg={process.env.REACT_APP_DEFAULT_PRODUCT_IMG!}
        />
      )}
    </div>
  );
};

export const ProductsComponent = styled(({ ...props }) => <CleanProductsComponent {...props} />)``;
