import { makeObjectData } from '@wdynamo/common/lib/utils';

// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { ICheckoutProps } from './models';

const initialState: ICheckoutProps = {
  docInfo: {
    loading: false,
    error: false,
    selectedDocType: '',
    docNumber: '',
    data: undefined
  },
  clientInfo: {
    gender: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phone: ''
  },
  clientAdress: {
    zipCode: '',
    street: '',
    number: '',
    floor: undefined,
    door: undefined,
    state: undefined,
    city: undefined
  },
  confirmation: {
    loading: false,
    error: false,
    paymentMethod: undefined,
    orderId: undefined
  },
  payment: {
    loading: false,
    error: false,
    paymentUrl: undefined,
    paymentSubscription: ''
  },
  product: undefined
};

export const checkoutReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Reset
    case ACTIONS.CHECKOUT.RESET:
      return initialState;

    // Choice a product
    case ACTIONS.PRODUCTS.CHOICE: {
      const product = action.payload.data;
      if (!product.thumbnail)
        product['thumbnail'] = {
          url: process.env.REACT_APP_DEFAULT_PRODUCT_IMG!,
          alt: product.title
        };
      return {
        ...state,
        docInfo: {
          loading: false,
          error: false,
          data: undefined
        },
        product: product
      };
    }

    // Find a doc number info
    case ACTIONS.CHECKOUT.FIND_DOC_NUMBER.FETCH:
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          loading: true,
          selectedDocType: action.payload.docType,
          docNumber: action.payload.docNumber,
          data: undefined
        }
      };
    case ACTIONS.CHECKOUT.FIND_DOC_NUMBER.SUCCESS:
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          loading: false,
          data: makeObjectData(action.payload.data)
        },
        clientInfo: {
          ...state.clientInfo,
          ...makeObjectData(action.payload.data)
        }
      };
    case ACTIONS.CHECKOUT.FIND_DOC_NUMBER.FAIL:
      return {
        ...state,
        docInfo: {
          ...state.docInfo,
          loading: false,
          error: true
        }
      };
    case ACTIONS.CHECKOUT.FIND_DOC_NUMBER.RESET:
      return initialState;

    // Update client's info
    case ACTIONS.CHECKOUT.UPDATE_CLIENT_INFO:
      return {
        ...state,
        clientInfo: {
          ...state.clientInfo,
          ...action.payload
        }
      };

    // Update address's info
    case ACTIONS.CHECKOUT.UPDATE_ADDRESS_INFO:
      return {
        ...state,
        clientAdress: {
          ...state.clientAdress,
          ...action.payload
        }
      };

    // Update payment's info
    case ACTIONS.CHECKOUT.UPDATE_PAYMENT_INFO:
      return {
        ...state,
        payment: {
          ...state.payment,
          ...action.payload
        }
      };

    // Checkout's confirmation
    case ACTIONS.CHECKOUT.CONFIRMATION.FETCH:
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          loading: true,
          error: false,
          paymentMethod: action.payload.checkout.payment_type
        }
      };
    case ACTIONS.CHECKOUT.CONFIRMATION.SUCCESS:
      return {
        ...state,
        docInfo: { ...initialState.docInfo },
        clientInfo: { ...initialState.clientInfo },
        clientAdress: { ...initialState.clientAdress },
        payment: { ...initialState.payment },
        confirmation: {
          ...state.confirmation,
          loading: false,
          error: false,
          orderId: action.payload.orderId
        }
      };
    case ACTIONS.CHECKOUT.CONFIRMATION.FAIL:
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          loading: false,
          error: true
        }
      };

    case ACTIONS.CHECKOUT.PAYMENT_URL.FETCH:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: true,
          error: false,
          paymentUrl: undefined
        }
      };
    case ACTIONS.CHECKOUT.PAYMENT_URL.SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false,
          paymentUrl: action.payload.paymentUrl
        }
      };
    case ACTIONS.CHECKOUT.PAYMENT_URL.FAIL:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
};
