// eslint-disable-next-line no-unused-vars
import { SiteMap } from '@wdynamo/common';

import { uris } from './siteMap';
import { Products } from './views/Products';
import { Checkout } from './views/Checkout';

type AppConfig = {
  apiUrl: string;
  sitemapUrls: SiteMap[];
};

const sitemapUrls: SiteMap[] = [
  { ...uris.products, component: Products, exact: true },
  { ...uris.checkout, component: Checkout, exact: true }
];

const Config: AppConfig = {
  apiUrl: process.env.REACT_APP_PUBLIC_URL as string,
  sitemapUrls
};

export default Config;
