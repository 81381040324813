export const uris = {
  products: {
    uri: '/products',
    label: 'Productos'
  },
  checkout: {
    uri: '/checkout',
    label: 'Realizar compra'
  }
};
