import React from 'react';
import { StylesProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
import { Header, SiteMap } from '@wdynamo/common';

import Config from './config';

import './App.scss';
import { uris } from './siteMap';

interface AppProps {
  className?: string;
  props?: any;
}

const CleanApp: React.FC<AppProps> = (options: AppProps) => (
  <Router>
    <div className={options.className}>
      <StylesProvider injectFirst>
        {/* Fix Header workarround */}
        <Header
          className='header'
          logoContent={
            <Link to={uris.products.uri}>
              <img src={process.env.REACT_APP_HEADER_IMG!} alt={process.env.REACT_APP_HEADER_IMG!.split('/').pop()} />
            </Link>
          }
          username={undefined}
        />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} className='page-content'>
            {/* start router's sitemap */}

            <Switch>
              {Config.sitemapUrls.map((route: SiteMap) => (
                <Route key={route.uri} exact={route.exact} path={route.uri} component={route.component} />
              ))}
              <Redirect to={uris.products.uri} />
            </Switch>
            {/* end router's sitemap */}
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </StylesProvider>
    </div>
  </Router>
);

export const App = styled(({ ...props }) => <CleanApp {...props} />)`
  .header {
    background-color: var(--color-white);
    padding: 10px 0;
    box-shadow: none;
    height: 100px;
    @media screen and (max-width: 450px) {
      height: 120px;
    }
    > div {
      padding: 0 30px;
      margin: auto;
    }
    .header-logo img {
      height: 80px;
      width: auto;
      object-fit: cover;
      object-position: 20% 10%;
      @media screen and (max-width: 450px) {
        height: 100px;
      }
    }
  }
  .page-content {
    padding: 20px 0;
    min-height: 60vh;
  }
`;
