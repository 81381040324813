import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';
// eslint-disable-next-line no-unused-vars
import { IBaseStateProps } from './views/ActionTypes';
import { productsReducer } from './views/Products';
import { checkoutReducer } from './views/Checkout';

const sagaMiddleware = createSagaMiddleware();

export interface IAppState {
  productsReducer: IBaseStateProps;
  checkoutReducer: IBaseStateProps;
}

const reducers = combineReducers({
  productsState: productsReducer,
  checkoutState: checkoutReducer
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export const Store = store;
export type RootState = ReturnType<typeof reducers>;
